
export default function useForm (request, onSuccess = () => {}, onFailure = () => {}) {
  const form = ref({})
  const formErrors = ref({})
  const loading = ref(false)

  async function submit (...args) {
    formErrors.value = {}
    loading.value = true
    let data
    try {
      data = await request(form.value, ...args)
      onSuccess(data)
    }
    catch (error) {
      console.error(error)
      data = error.data ?? error
      onFailure(data, error.status)
    }
    loading.value = false

    if (data?.errors) {
      formErrors.value = data.errors
    }

    if (data?.message) {
      formErrors.value = { general: data.message }
    }

    return data
  }

  return {
    form,
    loading,
    errors: formErrors,
    submit
  }
}
